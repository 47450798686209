body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.card {
  padding: 1rem;
  margin: 0.75rem;
  border: 1px solid #efefef;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  height: 350px;
  text-align: center;
  box-sizing: border-box;
}

.card > img {
  border-radius: 50%;
  transition: all 0.1s linear;
  transform: rotate(27deg) scale(0.9);
}

.card:hover > img {
  transform: rotate(0deg) scale(1);
  box-shadow: -4px -4px rgba(255, 0, 0, 0.5), 4px -4px rgba(0, 255, 0, 0.5),
    0px 4px rgba(0, 0, 255, 0.5);
}

.card > .details {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}

.card > .description {
}

.card > .tags {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.tags .tag {
  border-radius: 0.25rem;
  box-shadow: 5px red;
  margin-right: 1rem;
  padding: 0.25rem 1rem;
  color: white;
  border: 1px solid rgba(255,255,255,0.5);
  animation: bulge 1s ease-in-out alternate-reverse infinite;
}

.details > .title {
  font-weight: bold;
  grid-column: 1 / 3;
}

.details > .subtitle {
  text-align: right;
  border-right: 1px solid #666;
  padding-right: 0.5rem;
}

.details > .company {
  text-align: left;
  margin-left: 0.5rem;
}

.switch {
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,0.8);
  color: white;
  z-index: 1000;
  padding: 1rem;
  border-top-left-radius: 1rem;
}

@keyframes bulge {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.05);
  }
}